import { WebpImageProps } from "@/types/components/webpImage";
import { Transition } from "@headlessui/react";
import { Fragment, ImgHTMLAttributes, useEffect, useState } from "react";

const WebpImage = ({ fallback = '', preferSizes = [], className = '', lazy = false, ...props }: ImgHTMLAttributes<HTMLImageElement> & WebpImageProps) => {
    const getFormat = (filename: string) => {
        return filename.split('.').pop();
    };
 
    const format = getFormat(fallback);

    return (
        <picture className={className == '' ? 'w-full h-auto object-cover' : ''}>

            <source
                media={`(min-width: 100px)`}
                srcSet={`${fallback.replace(/\.[^.]+$/, '')}.webp`}
                type={`image/${format}`}
            />
            <source
                media={`(min-width: 100px)`}
                srcSet={`${fallback.replace(/\.[^.]+$/, '')}.${format}`}
                type={`image/${format}`}
            />
            <img loading={lazy ? 'lazy' : 'eager'} src={fallback} {...props} {...props} className={className == '' ? 'w-full h-auto object-cover' : className}/>
        </picture>
    )
}

export default WebpImage;